import React, { useCallback, useMemo } from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { useUser } from "$hooks/auth";
import { useCreateComplaint } from "$hooks/complaint";

import Section from "$ui/section";
import Heading from "$ui/heading";
import Input from "$ui/input";
import TextArea from "$ui/textArea";
import Button from "$ui/button";
import FieldSet from "$ui/fieldSet";
import Checkbox from "$ui/checkbox";
import RadioButton from "$ui/radiobutton";
import PincodeField from "$ui/pincodeField";

import Pdf from "$images/Authorization-to-CRO.pdf";

const boolEnum = ["true", "false"];

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const complaintSchema = Yup.object().shape({
  transactionDate: Yup.date()
    .max(new Date())
    .required("When did this transaction occur?"),
  transactionTime: Yup.string().required("When did this transaction occur?"),
  transactionMedium: Yup.array()
    .of(Yup.string())
    .min(1, "Select atleast one of given options")
    .required("Where / How did this transaction occur?"),
  transactionMethod: Yup.array()
    .of(Yup.string())
    .min(1, "Select atleast one of given options")
    .required("How did you pay?"),
  transactionPurpose: Yup.array()
    .of(Yup.string())
    .min(1, "Select atleast one of given options")
    .required("What was the purpose of this transaction?"),
  initialMeetWithOpposition: Yup.array()
    .of(Yup.string())
    .min(1, "Select atleast one of given options")
    .required("How did you meet the other party?"),

  hasGivenDisclosureConsent: Yup.string().oneOf(boolEnum),
  hasSignedAgreement: Yup.string().oneOf(boolEnum),
  hasContactedLawyer: Yup.string().oneOf(boolEnum),
  hasTriedSettlementWithOpposition: Yup.string().oneOf(boolEnum),
  hasApproachedOtherAgencies: Yup.string().oneOf(boolEnum),
  hasGoneCourt: Yup.string().oneOf(boolEnum),
  hasBeenSued: Yup.string().oneOf(boolEnum),

  lossAmount: Yup.number(),
  issueDescription: Yup.string()
    .trim()
    .required("Please provide a detailed description of this incident"),
  issueType: Yup.string().oneOf([
    "medical",
    "insurance",
    "real-estate",
    "online",
    "hotel",
    "transportation",
    "food-packaging",
    "other",
  ]),
  suggestedResolutionApproach: Yup.string()
    .trim()
    .required("How would you like to solve this problem?"),

  oppositionName: Yup.string()
    .trim()
    .required(
      "Please provide the name of the firm / organization that you want to deal with"
    ),
  oppositionEmail: Yup.string()
    .email("Invalid email")
    .required(
      "Please provide the email of the firm / organization that you want to deal with"
    ),
  oppositionPhone: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required(
      "Please provide the phone number of the firm / organization that you want to deal with"
    ),
  oppositionPincode: Yup.string()
    .matches(/^[0-9]+$/, "Pincode can contain only digits")
    .min(6, "Pincode must be of exactly 6 digits")
    .max(6, "Pincode must be of exactly 6 digits")
    .required(
      "Please provide the pincode of the firm / organization that you want to deal with"
    ),
  oppositionState: Yup.string().required(
    "Please provide the state of the firm / organization that you want to deal with"
  ),
  oppositionCity: Yup.string().required(
    "Please provide the city of the firm / organization that you want to deal with"
  ),
  oppositionAddress: Yup.string()
    .trim()
    .required(
      "Please provide the address of the firm / organization that you want to deal with"
    ),
  oppositionPersonOfImportance: Yup.string()
    .trim()
    .required(
      "Please provide a name of an important person from the firm / organization that you want to deal with"
    ),
});

const ComplaintForm = () => {
  const { user } = useUser();
  const { mutateAsync } = useCreateComplaint();

  const initialValues = useMemo(
    () => ({
      fullName: user?.fullName,
      email: user?.email,
      phone: user?.phone,
      pincode: user?.pincode,
      city: user?.city,
      state: user?.state,
      address: user?.address,

      transactionDate: "",
      transactionTime: "",
      transactionMedium: "",
      transactionMethod: "",
      transactionPurpose: "",
      initialMeetWithOpposition: "",

      hasGivenDisclosureConsent: "",
      hasSignedAgreement: "",
      hasContactedLawyer: "",
      hasTriedSettlementWithOpposition: "",
      hasApproachedOtherAgencies: "",
      hasGoneCourt: "",
      hasBeenSued: "",

      lossAmount: "",
      issueDescription: "",
      issueType: "online",
      suggestedResolutionApproach: "",

      oppositionName: "",
      oppositionPhone: "",
      oppositionEmail: "",
      oppositionAddress: "",
      oppositionPincode: "",
      oppositionState: "",
      oppositionCity: "",
      oppositionPersonOfImportance: "",
    }),
    [user]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const payload = {};

      // Get values which are the same for API call
      [
        "transactionMedium",
        "transactionMethod",
        "transactionPurpose",
        "initialMeetWithOpposition",
        "lossAmount",
        "issueDescription",
        "issueType",
        "suggestedResolutionApproach",
      ].forEach((key) => (payload[key] = values[key]));

      // Transform boolean fields
      [
        "hasGivenDisclosureConsent",
        "hasApproachedOtherAgencies",
        "hasBeenSued",
        "hasContactedLawyer",
        "hasGoneCourt",
        "hasSignedAgreement",
        "hasBeenSued",
        "hasTriedSettlementWithOpposition",
      ].forEach(
        (key) => (payload[key] = values[key] === "true" ? true : false)
      );

      // Transform opposition fields
      payload.opposition = {};

      [
        "oppositionName",
        "oppositionPhone",
        "oppositionEmail",
        "oppositionAddress",
        "oppositionPincode",
        "oppositionState",
        "oppositionCity",
        "oppositionPersonOfImportance",
      ].forEach((key) => {
        // Get required field name expected by backend
        const property = key.split("opposition")[1];
        const fieldName = property[0].toLowerCase() + property.slice(1);

        payload.opposition[fieldName] = values[key];
      });

      // Transform date and time fields
      const date = new Date(values.transactionDate);
      const [hours, minutes] = values.transactionTime.split(":");

      date.setHours(date.getHours() - date.getHours() + Number.parseInt(hours));
      date.setMinutes(
        date.getMinutes() - date.getMinutes() + Number.parseInt(minutes)
      );

      payload.transactionDate = date;

      // Fire request to the API
      try {
        await mutateAsync(payload);
        toast.success("Complaint filed successfully!");
        navigate("/me/dashboard");
      } catch (err) {
        toast.error(err.response?.data.message || "Something went wrong");
      }
    },
    [mutateAsync]
  );

  return (
    <Section className="phone:px-0 pt-40">
      <Heading>Fill out the form to raise a complaint</Heading>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={complaintSchema}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            id="section-form"
            autoComplete="off"
            className="py-10 px-5 space-y-10"
          >
            <div className="grid grid-cols-2 gap-10 tab-port:grid-cols-none">
              <FieldSet legend="your information">
                <Input
                  name="fullName"
                  placeholder="full name"
                  value={formik.values.fullName}
                  disabled
                />

                <Input
                  name="email"
                  type="email"
                  placeholder="email address"
                  value={formik.values.email}
                  disabled
                />

                <Input
                  name="phone"
                  type="tel"
                  placeholder="phone number"
                  value={formik.values.phone}
                  disabled
                />

                <Input
                  placeholder="street address"
                  value={formik.values.address}
                  disabled
                />

                <div className="grid grid-cols-3 gap-x-5">
                  <Input
                    name="pincode"
                    placeholder="pincode"
                    value={formik.values.pincode}
                    disabled
                  />

                  <Input
                    name="state"
                    placeholder="state"
                    value={formik.values.state}
                    disabled
                  />

                  <Input
                    name="city"
                    placeholder="city"
                    value={formik.values.city}
                    disabled
                  />
                </div>
              </FieldSet>

              <FieldSet legend="who is your complaint against?">
                <Input
                  name="oppositionName"
                  placeholder="full name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.oppositionName &&
                    formik.errors.oppositionName
                  }
                />
                <Input
                  name="oppositionAddress"
                  placeholder="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.oppositionAddress &&
                    formik.errors.oppositionAddress
                  }
                />
                <Input
                  name="oppositionPhone"
                  placeholder="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.oppositionPhone &&
                    formik.errors.oppositionPhone
                  }
                />
                <Input
                  name="oppositionEmail"
                  placeholder="email address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.oppositionEmail &&
                    formik.errors.oppositionEmail
                  }
                />
                <Input
                  name="oppositionPersonOfImportance"
                  placeholder="Name of Opposite party/ Organisation / Web portal / Service providers / vendor"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.oppositionPersonOfImportance &&
                    formik.errors.oppositionPersonOfImportance
                  }
                />
                <Input
                  name="oppositionPersonOfImportance"
                  placeholder="Name of product / service"
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.oppositionPersonOfImportance &&
                    formik.errors.oppositionPersonOfImportance
                  }
                />

                <div className="grid grid-cols-3 gap-x-5">
                  <PincodeField
                    name="oppositionPincode"
                    placeholder="pincode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.oppositionPincode &&
                      formik.errors.oppositionPincode
                    }
                    value={formik.values.oppositionPincode}
                    setFieldValue={formik.setFieldValue}
                    stateFieldName="oppositionState"
                    cityFieldName="oppositionCity"
                  />

                  <Input
                    name="oppositionState"
                    placeholder="state"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.oppositionState &&
                      formik.errors.oppositionState
                    }
                    value={formik.values.oppositionState}
                    disabled
                  />
                  <Input
                    name="oppositionCity"
                    placeholder="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.oppositionCity &&
                      formik.errors.oppositionCity
                    }
                    value={formik.values.oppositionCity}
                    disabled
                  />
                </div>
              </FieldSet>
            </div>

            <FieldSet
              legend="Have you complained to anyother platform earlier yes or no ( If yes Name of platform )"
              variant="grid"
            >
              <RadioButton name="plt" label="Yes" />
              <RadioButton name="plt" label="No" />
              <Input name="platfrom" placeholder="Name of platform" />
            </FieldSet>

            <FieldSet
              variant="horizontal"
              legend="when did this transaction / problem occur?"
            >
              <Input
                type="date"
                name="transactionDate"
                placeholder="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.transactionDate &&
                  formik.errors.transactionDate
                }
              />
              <Input
                type="time"
                name="transactionTime"
                placeholder="time"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.transactionTime &&
                  formik.errors.transactionTime
                }
              />
            </FieldSet>

            <FieldSet legend="Resolution" variant="grid">
              <Checkbox name="refund" label="Refund" />
              <Checkbox name="Replacement" label="Replacement" />
              <Checkbox name="Apology" label="Apology" />
              <Checkbox name="Compensation" label="Compensation" />
            </FieldSet>

            <FieldSet legend="Did you sign any agreement?" variant="horizontal">
              <RadioButton
                name="hasSignedAgreement"
                label="Yes"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <RadioButton
                name="hasSignedAgreement"
                label="No"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.hasSignedAgreement && (
                <div className="text-xs text-red-400 mt-1 min-h-[1rem] col-span-full">
                  {formik.errors.hasSignedAgreement}
                </div>
              )}
            </FieldSet>

            <FieldSet
              legend="Have you started a court action?"
              variant="horizontal"
            >
              <RadioButton
                name="hasGoneCourt"
                label="Yes"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <RadioButton
                name="hasGoneCourt"
                label="No"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Input
                placeholder={`If yes then give Status of Case ${formik.values.hasGoneCourt}`}
                // disabled={!formik.values.hasGoneCourt === "False"}
              />
              {formik.errors.hasGoneCourt && (
                <div className="text-xs text-red-400 mt-1 min-h-[1rem] col-span-full">
                  {formik.errors.hasGoneCourt}
                </div>
              )}
            </FieldSet>

            <FieldSet legend="Product or Service value (INR)">
              <Input
                type="number"
                name="lossAmount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="amount in INR"
                error={formik.touched.lossAmount && formik.errors.lossAmount}
              />
            </FieldSet>

            <FieldSet legend="Select a type which is most relevant to your complaint">
              <select
                value={formik.values.issueType}
                name="issueType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="Airline complaints">Airline complaints</option>
                <option value="Railway complaints">Railway complaints</option>
                <option value="Banking complaints">Banking complaints</option>
                <option value="Postal complaints">Postal complaints</option>
                <option value="Medical complaints">Medical complaints</option>
                <option value="Travel complaints">Travel complaints</option>
                <option value="Telecom complaints">Telecom complaints</option>
                <option value="E-commerce complaints">
                  E-commerce complaints
                </option>
                <option value="Insurance complaints">
                  Insurance complaints
                </option>
                <option value="Real estate complaints">
                  Real estate complaints
                </option>
                <option value="Home appliances complaints">
                  Home appliances complaints
                </option>
                <option value="Electricity complaints">
                  Electricity complaints
                </option>
                <option value="Government service complaints">
                  Government service complaints
                </option>
                <option value="Lgp/ petroleum complaints">
                  LPG/ petroleum complaints
                </option>
                <option value="Food safety complaints">
                  Food safety complaints
                </option>
                <option value="Mobile / laptop complaints">
                  Mobile / laptop complaints
                </option>
                <option value="Automobiles complaints">
                  Automobiles complaints
                </option>
                <option value="Education complaints">
                  Education complaints
                </option>
                <option value="Internet service providers complaints">
                  Internet service providers complaints
                </option>
                <option value="D2H complaints">D2H complaints</option>
                <option value="Others">Others</option>
              </select>
            </FieldSet>

            <FieldSet legend="Please describe your complaint in detail">
              <TextArea
                name="issueDescription"
                placeholder="Describe your issue"
                className="col-span-full"
                value={formik.values.issue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.issueDescription &&
                formik.errors.issueDescription && (
                  <div className="text-xs text-red-400 mt-1 min-h-[1rem] col-span-full">
                    {formik.errors.issueDescription}
                  </div>
                )}
            </FieldSet>
            <FieldSet legend="Attach files (proof of evidences)">
              <Input
                type="file"
                name="files"
                multiple
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // placeholder="amount in INR"
                // error={formik.touched.lossAmount && formik.errors.lossAmount}
              />
            </FieldSet>

            <FieldSet legend="How would you like your complaint to get solved?">
              <TextArea
                name="suggestedResolutionApproach"
                placeholder="Describe your approach"
                className="col-span-full"
                value={formik.values.suggestedResolutionApproach}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.suggestedResolutionApproach &&
                formik.errors.issueDescription && (
                  <div className="text-xs text-red-400 mt-1 min-h-[1rem] col-span-full">
                    {formik.errors.suggestedResolutionApproach}
                  </div>
                )}
            </FieldSet>
            <FieldSet legend="Authorizing CRO">
              <div className="h-56 overflow-y-scroll space-y-4 bg-white rounded-lg p-4 mb-10">
                  <h1 className=" font-bold text-center text-lg py-4">Authorization to CRO- Consumer Rights 
                  Organisation/ Upbhoktha Adhikar sangathan : -</h1>
                <p>
                  I do hereby Authorize and appoint Consumer Rights Organization/ Upbhokta Adhikar
Sangathan (hereinafter referred and called as “CRO”) for following acts on my behalf.
To act, appear and plead in the above-noted consumer case in any Court or in any other
Forum/Tribunal/commision in which the same may be tried or heard and also in the
appellate Court including state commission/national commission subject to payment of
fees separately for each Court by me/us.

                </p>
                <p>
                  To sign, file, verify and present pleadings, appeals, cross-objections or petitions for
executions review revision, withdrawal, compromise or other petitions or affidavits or
other documents as may be deemed necessary or proper for the purpose of the said
case in all its stages subject to payment of fees for each stage.
To file and take back documents, to admit and/or deny the documents of the opposite
party.
                </p>
                <p>
                  To withdraw or compromise the said case or submit to arbitration any differences or
disputes that may arise touching or in any manner relating to the said case.
To take execution proceedings.

                </p>
                <p>
                  To deposit, draw and receive monthly cheques, cash and grant receipts thereof and to
do all other acts and things which may be necessary to be done for the progress and in
the course of the prosecution of the said case.
                </p>
                <p>
                  To appoint and instruct any other Legal Practitioner authorizing him to exercise the
power and authority hereby conferred upon the Advocate whenever he may think fit to
do so and to sign the power of attorney on our behalf
                </p>
                <p>
                  And I/We the undersigned do hereby agree to rectify and confirm all acts done by the
CRO and Advocate appointed by CRO or his substitute in the matter as my/our own
acts, as if done by me/us to all intents and purposes.
And I/We undertake that I/We or my/our duly authorized agent would appear in Court on
all hearings and will inform the CRO for appearance when the case is called.
And I/We the undersigned do hereby agree not to hold the CRO/advocate or his
substitute responsible for the result of the said cas
                </p>
                <p>
                  The adjournment costs and Legal expenses whenever ordered by the Court shall be of
the CRO/Advocate Appointed by CRO, which he shall receive and retain for himself.
And I/We the undersigned to hereby agree that in the event of the whole or part of the
fee agreed by me/us to be paid to the CRO/advocate remaining unpaid he shall be
entitled to withdraw from the prosecution of the said case until the same is paid up. The
fee settled is only for the above case and above Court. I/we hereby agree that once fee
is paid, I/We will not be entitled for the refund of the same in any case whatsoever and if
the case prolongs for more than 3 years the original fee shall be paid again by me/us.
I hereby fully agreed to give my complaint details to publish on social media on my
behalf by the CRO
                </p>
                <p>
                  i am giving my concern to the CRO to publish my details in survey report, media report,
on websites etc.,

                </p>
                <p>
                  Declaration by me :- I the above named complainant hereby declare and undertake that
all the details and documents furnished here are true and correct to the best of my
knowledge and I rely on it
                </p>

              </div>
              <Checkbox
                name="authoriseCRO"
                label=" I Authorize CRO Consumer Rights Organisation to send notice and fight case on behalf of myself"
              />
   
            </FieldSet>
            <FieldSet legend="Declaration" >
              <Checkbox
                name="auth"
                label="I Declare that all details filled and files attached in the complaint form are true and
correct"
              />
            </FieldSet>

            <FieldSet legend="Consent and verification">
              <p>
                I affirm, under the penalties for perjury, that the foregoing
                representations, and those in all attachments, are true. The
                information i have provided in this complaint form is based upon
                my personal knowledge. I consent to the Consumer Rights
                Organisation Obtaining or releasing any informatioin in
                furtherance of the disposition of this complaint. I understand if i provide any wrong information than i will be liable for legal proceedings
against me

              </p>
            </FieldSet>

            <div className="col-span-full text-right phone:text-center">
              <Button
                type="submit"
                isLoading={formik.isSubmitting}
                variant="filled"
                className="w-1/3"
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Section>
  );
};

export default ComplaintForm;
