import { useMutation, useQueryClient } from 'react-query';

import { createComplaint } from '$utils/queryFunctions';

export const useCreateComplaint = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createComplaint,
    onSuccess() {
      queryClient.invalidateQueries(['complaints', 'my-complaints']);
    }
  });
};
