import classNames from 'classnames';
import React from 'react';

const FieldSet = ({ legend, children, variant = 'plain' }) => (
  <fieldset className='bg-gray-100'>
    <p className='bg-primary text-white px-4 py-1 capitalize'>{legend}</p>

    <div
      className={classNames('p-8', {
        'space-y-4': variant === 'plain',
        'grid grid-flow-col auto-cols-1fr gap-x-10 gap-y-2 tab-land:grid-flow-row':
          variant === 'horizontal',
        'grid grid-cols-3 gap-5': variant === 'grid'
      })}>
      {children}
    </div>
  </fieldset>
);

export default FieldSet;
