import React from 'react';

/* eslint-disable jsx-a11y/label-has-associated-control */
const Checkbox = ({ label, name, ...rest }) => {
  const computedId = `${label.replaceAll(' ', '-')}-${name}`;

  return (
    <div className='flex items-center'>
      <input
        type='checkbox'
        name={name}
        id={computedId}
        className='hidden peer'
        value={label}
        {...rest}
      />

      <label
        htmlFor={computedId}
        className='h-5 w-5 border-2 rounded-sm border-primary p-[2px] before:h-full before:w-full before:block before:rounded-sm peer-checked:before:bg-primary'></label>

      <label htmlFor={computedId} className='ml-2'>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
