import React from "react";
import { Helmet } from "react-helmet";

import ProtectedRoute from "$components/protectedRoute";
import ComplaintForm from "$components/complaints/complaintForm";

import Heading from "$ui/heading";
import Section from "$ui/section";
import Anchor from "$ui/anchor";

import heroImg from "$images/complaint/hero.png";

const ComplaintPage = () => {
  return (
    <ProtectedRoute>
      <Helmet>
        <title>TheCRO &mdash; Complaint</title>
      </Helmet>

      <main>
        <header
          className="min-h-screen text-white bg-primary grid grid-cols-2 gap-20 items-center px-24 tab-port:grid-cols-none tab-port:min-h-[60vh] phone:px-6"
          style={{ clipPath: "polygon(0 0, 100% 0, 100% 85%, 0 100%)" }}
        >
          <div className="space-y-6">
            <Heading variant="primary-white" className="mt-12">
              Got a complaint?
            </Heading>

            <p>
              If your complaint has not been solved or you are not satisfied
              with the response, we may be able to help. If you have contacted
              the company concerned and they have not solved the problem or you
              are not satisfied with their response, we may be able to help. We
              are here to act on your behalf and investigate your complaint.
              File your complaint below or contact our Consumer Support team on
              +91 9977-252-252
            </p>

            <Anchor href="#section-form" variant="filled-plain">
              File a complaint
            </Anchor>
          </div>

          <img src={heroImg} alt="HERO" className="tab-port:hidden" />
        </header>
        <ComplaintForm />
        <Section>
          <Heading>Top tips</Heading>

          <article className="space-y-6 mt-12 text-gray-600">
            <p>
              1. Give the company an opportunity to put things right. If you are
              unsure about how to make a complaint, contact our Consumer Support
              team on +91 966 968 9021. They can guide you through making an
              effective complaint (Please note: calls will be recorded In order
              to improve the quality of our service and for business purposes)
            </p>

            <p>
              2. Ask the company for a copy of their complaints procedure so you
              can be sure that they are handling your complaint correctly.
            </p>

            <p>
              3. If you are complaining by telephone, make a note of what you
              want to say and how you want the problem to be solved.
            </p>

            <p>
              4. Make sure you have the necessary documents from the company
              ready (bills, letters etc). The documents may have a reference
              number that you may need when speaking to the company.
            </p>

            <p>
              5. Have a pen and paper ready so you can note the person's name
              you were talking to, and a record of what they said.
            </p>
          </article>
        </Section>

        <Section className="bg-primary text-white ">
          <Heading variant="tertiary-white" className="mt-12">
            What happens when you make a complaint?
          </Heading>

          <ul className="list-disc mt-8 space-y-4 px-6">
            <li>
              We will contact the company concerned and discuss how we can
              resolve your complaint.
            </li>
            <li>
              We will request copies of all correspondence exchanged in relation
              to your complaint.
            </li>
            <li>We will keep you informed at every step of the complaint.</li>
            <li>
              Remember: If you have contacted the company concerned and they
              have not solved the problem or you are not satisfied with their
              response, contact our Consumer Support team on +91 966 968 9021 or
              make a complaint online.
            </li>
          </ul>
        </Section>
      </main>
    </ProtectedRoute>
  );
};

export default ComplaintPage;
